<template>
    <!-- 
        Сторінка "Послуги"
        - дозволяє переглянути список послуг
        - відкрити картку послуги
        - викликати створення послуги
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- кнопка відображення інфо-блоків -->
    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
        </button>
    </div>

    <!-- 
        Інформаційні блоки з підсумком по -
        - усього послуг
        - кількість успішних послуг
        - кількість не успішних послуг
    -->
    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />

    <!-- 
        Компонент таблиці
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога) та 511 (створення послуги)
        - @create - подія, яка спрацьовує при створенні нової послуги
        - @open - подія, яка спрацьовує при відкритті картки послуги
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['511'] || this.perms['2000']" 
        @create="showModalAdd = true"
        @open="open"
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
    />

    <!-- 
        Виклик вікна створення картки послуги
        - для відкриття застосовується перевірка значення modalCreatedBox
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <created 
        v-if="showModalAdd" 
        @close="showModalAdd = false"
    ></created>

    <!-- 
        Виклик відкриття картки послуги
        - для відкриття застосовується перевірка значення showModal
        - @close - подія, яка спрацьовує при закритті картки
        - :objcard - параметр-об'єкт з даними по послуги
    -->
    <viewcard 
        v-if="showModal == true" 
        @close="closeCard" 
        :objcard="objcard"
    ></viewcard>

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import infoblocks from '@/components/info/blocks' // інфо-блоки
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import created from './createservice' // картка створення послуги
import viewcard from './view' // картка послуги
import { Services } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateServices, mutateStatusServices } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Services();

export default {
    data() {
        return {
            title: this.$t('Services'), // Заголовок
            showModalAdd: false, // прапорець відображення вікна створення послуги
            showModal: false, // прапорець відображення вікна картки послуги
            objcard: {}, // Об'єкт з даними послуги для відображення в модальному вікні
            infoblockShow: false, // прапорець відображення інфо-блоків
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "services"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                status: '',
                nomenclatureName: '',
                ot: 'desc',
                of: 'nomenclatureId'
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: 'ID',
                    text: "nomenclatureId",
                    align: "left",
                    width: 200,
                    status: false
                },
                {
                    name: this.$t("service"),
                    text: "nomenclatureName",
                    align: "left",
                    width: 300,
                    status: true,
                    mutate: (item) => this.mutateServices(item),
                },
                {
                    name: this.$t("phone_num"),
                    text: "userPhone",
                    align: "right",
                    width: 200,
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color
                },
                {
                    name: this.$t("Price"),
                    text: "price",
                    align: "right",
                    width: 200,
                    status: true,
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    width: 200,
                    status: true,
                    mutate: (item) => this.nameWorker(item)
                },
                {
                    name: this.$t("createDate"),
                    text: "createDatetime",
                    align: "right",
                    width: 200,
                    status: true,
                },
                {
                    name: this.$t("Comment"),
                    text: "description",
                    align: "right",
                    width: 200,
                    status: true,
                    mutate: (item) => item == "" ? '-' : item,
                },
            ],
            rows: [], // Дані для відображення у таблиці
            // filterAll: [
            //     {
            //         page: "service",
            //         name: "service",
            //         category: "0",
            //         items: []
            //     },
            //     {
            //         name: "Status",
            //         category: "0",
            //         items: []
            //     }
            // ],
            // selectService: "0",
            // selectStatus: "0",
            information: [
                // Дані компонента, які відображають інформацію про кількість
                // Кожен елемент масиву містить:
                // * title - назву інформаційного блоку,
                // * value - кількість послуг
                // * icon - клас іконки,
                // * color - колір інформаційного блоку,
                // * sum - загальну суму 
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('successfully'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('notSuccessful'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
            ]
        }
    },
    components: {
        PageHeader,
        created,
        tablecustom,
        infoblocks,
        viewcard
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про послуги з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getService(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;

                    this.information[0].value = result.data.total;
                    this.information[1].value = result.data.countProblemSolved;
                    this.information[2].value = result.data.countProblemNotSolved;
                } else {
                    this.$toast.error(this.$t('error') + ` #510`);
                }
            })
        },
        closeCard(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці modalDialog в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.showModal = false;
            this.getdata(this.objParams.page);
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        open: function(e){
            /**
                Функція для відкриття модального вікна з інформацією про послугу за її ідентифікатором.
                * @param {Object} e - об'єкт картки послуги

                Викликає API для отримання інформації про послуги.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.
            */

            apiServe.getCardService(e.nomenclatureId).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);
                }
            })
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за коментарем
            */

            this.objParams.search = e
            this.getdata()
        },
        // change(name, e){
        //     if(name == 'service'){
        //         this.selectService = e;
        //     } else if(name == 'Status'){
        //         this.selectStatus = e;
        //     }
        // },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        // clearFilter(){
        //     this.selectService = "0";
        //     this.selectStatus = "0";
        // },
        mutateServices(e){
            /*
                Функція для застосування перекладу типу послуги
            */
            return mutateServices(e)
        },
        mutateStatus(e){
            /*
                Функція для застосування перекладу до статусу послуги
            */
            return mutateStatusServices(e)
        },
        nameWorker(item){
            /*
                Функція для отримання співробітника
            */
            return nameWorker(item)
        }
    },
    mounted() {
        // Cлухання події saveService для оновлення даних після збереження послуги
        this.eventBus.off('saveService');
        this.eventBus.on('saveService', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}
</script>